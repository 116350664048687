import {
    IMwrTransTextItem,
    IMwrTransTextItems,
    IMwrTransTopicItem,
    IMwrTransTopicItems
} from "../typeDef/translationData";

let storedTranslationData: IMwrTransTopicItems = null;

export function setTranslationData (translationData: any): void {
    storedTranslationData = translationData;
}
export function getTranslationPerTopic (topicTechRef: string, lang: string): string {
    const transTopic: IMwrTransTopicItem = getTransTopic(topicTechRef);
    if (transTopic) {
        return getTransTextPerTopic(transTopic, lang);
    
    } else {
        console.log("Error: No Translation Topic found for " + topicTechRef);
        return "<??? - no Topic>";
    }
}

export function getMultiTransTopic (topicTechRef: string): IMwrTransTopicItems {
    if (storedTranslationData) {
        return storedTranslationData.filter((transTopic: IMwrTransTopicItem) => transTopic.transTechCode.startsWith(topicTechRef));
    } else {
        return [];
    }
}


function getTransTopic(topicTechRef: string): IMwrTransTopicItem {
    if (storedTranslationData) {
        return storedTranslationData.find((topicItem) => topicItem.transTechCode === topicTechRef);
    } else {
        return null;
    }
}

function getTransTextPerTopic(transTopic: IMwrTransTopicItem, lang: string): string {
    if (!transTopic || !transTopic.transTextList || transTopic.transTextList.length === 0 ) {
        console.log("Error: There is no Translation Items for that topic: " + transTopic.transTechCode);
        return "<??? - No Translation";
    }
    const allTransTextItems: IMwrTransTextItems = transTopic.transTextList;
    // zuerst versuchen Translation für die gewünschte Sprache zu erhalten
    const langTranslateItem: IMwrTransTextItem = allTransTextItems.find((textItem: IMwrTransTextItem) => textItem.langCode === lang);
    if (langTranslateItem) {
        return langTranslateItem.transText;
    }
    console.log("Error: There is no Translation in that language for that topic: " + transTopic.transTechCode + " / " + lang);
    const firstTranslateItem: IMwrTransTextItem = allTransTextItems[0];
    return firstTranslateItem.transText;
}
