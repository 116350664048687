import * as React from "react";
import appStyles from "./App.css";
// import { StrictMode } from "react";

import {
    configureStore,
    createImmutableStateInvariantMiddleware,
} from '@reduxjs/toolkit'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PageHeader} from "./client/pages/start/PageHeader";
import {PageHeaderClassComponent} from "./client/pages/start/PageHeaderClassComponent";
import {BrowserRouter} from "react-router-dom";
import {AppRouting} from "./client/pages/start/AppRouting";
import sharedServices from "./config/sharedServices.json";
import {ServiceCall} from "./client/sharedLib/ServiceCall";
import allReducers from "./client/redux";
import { Provider } from "react-redux";

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
})

let store = configureStore({
    reducer: allReducers,
    middleware: [immutableInvariantMiddleware],
})


// DISPATCH

// store.dispatch(increment());

export default class App extends React.Component<{}, {}> {
    
    render() {
        return (
                <Provider store={store} >
                    <BrowserRouter>
                        <div className={appStyles.divApp}>
                            <PageHeader/>
{/*
                            <PageHeaderClassComponent/>
*/}
                            <AppRouting/>
                        </div>
                    </BrowserRouter>
                </Provider>
        );
        
    }
}

