import {IAppSettings, IAppSettingItem} from "../typeDef/appSettingsData";

export function getCurrentLanguage (appSettings: IAppSettings): string {
    return getAppSettingValueFromName(appSettings, "lang");
}

function getAppSettingValueFromName (appSettings: IAppSettings, name: string): string {
    if (appSettings) {
        const settingData = appSettings.find((settingItem: IAppSettingItem) => settingItem.name === name);
        if (settingData) {
            return settingData.value;
        }
    }
    return "";
}

export function getWindowSize (): string {
    const winWidth: number = window.innerWidth;
    if (winWidth > 1000) {
        return "L";
    }
    if (winWidth > 700) {
        return "M";
    }
    return "S";
}