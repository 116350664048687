import {ISpecDateObjectFromDate} from "../typeDef/dateLib";

export function convertDateToDateString (inDate: Date): string {
    let dateString: string = "";
    
    if (inDate !== null) {
        const workString: Date = new Date(inDate);
        const fullYear: number = workString.getFullYear();
        const monthNo: number = workString.getMonth() + 1;
        const dayNo: number = workString.getDate();
        
        dateString = "" + fullYear + "-" + (monthNo < 10 ? ("0" + monthNo) : monthNo) + "-" + (dayNo < 10 ? ("0" + dayNo) : dayNo);
    }
    return dateString;
}
export function getSpecDateObjectFromDate (inDate: Date): ISpecDateObjectFromDate {
    const date = new Date(inDate);
    date.setHours(0, 0, 0);
    return {
        dateDate: date,
        dateYear: date.getFullYear(),
        dateMonth: date.getMonth() + 1,
        dateDay: date.getDate(),
        weekDay: date.getDay()
    }
}
export function getWeekdayString (weekDay: number): string {
    const weekDayList: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
    if (weekDay >= 0 && weekDay <= 6) {
        return weekDayList[weekDay];
    }
    return "";
}