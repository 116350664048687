import React from 'react';
import { Form } from 'react-bootstrap';
import inputStyles from "./input.module.css";

interface IProps {
    name: string;
    type: string; // valid is: text, inputText, textArea, inputTextArea, number, inputNumber;
    isEditable: boolean;
    value: string;
    placeholder?: string;
    fnChangeHandler: (name: string, value: any) => void;
}

export class ShowTextWidget extends React.Component<IProps, {}> {
    
    onChangeHandler = (evt: any) => {
        const { name, isEditable, fnChangeHandler } = this.props;
        
        if (isEditable) {
            fnChangeHandler(name, evt.target.value);
        }
    }

    render() {
        const { value, name, type, isEditable } = this.props;
        const styleInputTextInput = (isEditable ? inputStyles.InputTextInputEdit : inputStyles.InputTextInputView);
        const placeholder = this.props.placeholder || "";
        const showValue = value ? value : ""; // set showValue to "" instead null;
        if (type === "text") {
            if (isEditable) {
                return (
                    <>
                        <Form.Control type={"text"} placeholder={placeholder} value={showValue} style={{width: "100%"}} onChange={(evt) => this.onChangeHandler(evt)} />
                    </>
                )
            }
            return (
                <>
                    {value}
                </>
            )
        }
        if (type === "textarea") {
            if (isEditable) {
                return (
                    <>
                        <Form.Control as={"textarea"} placeholder={placeholder} value={showValue} style={{width: "100%"}} rows={3} onChange={(evt) => this.onChangeHandler(evt)} />
                    </>
                )
            }
            return (
                <>
                    <Form.Control as={"textarea"} placeholder={placeholder} value={showValue} readOnly={true} style={{width: "100%"}} rows={3} />
                </>
            )
        }
        return (
                    <input className={styleInputTextInput} name={name} style={{width: "100%"}} type={"text"} value={showValue} />
        )
    }
}
