import React from 'react';
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic} from "../../sharedLib/translationLib";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {IRootState} from "../../redux";
import appStyles from "../../css/mwlaApp.module.css";
import {Accordion, Alert, Badge, Button, Card} from "react-bootstrap";
import {ServiceCall} from "../../sharedLib/ServiceCall";
import apiServices from "../../config/apiServices.json";
import {IMwdBookItem, IMwdBookItems} from "../../typeDef/bookItem";
import {getSpecDateObjectFromDate, getWeekdayString} from "../../sharedLib/dateLib";
import {ISpecDateObjectFromDate} from "../../typeDef/dateLib";
//import PropTypes from 'prop-types';

interface iDateItem {
    dateObject: ISpecDateObjectFromDate;
    bookItemList: IMwdBookItems;
}

interface IState {
    dummy: string;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adMwrTransTopic: state.appData.mwrTransTopic,
        adMwdBookItems: state.appData.mwdBookItem,
        appSettings: state.appData.appSettings,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;


export default class RequestHomeComponent extends React.Component<Props, IState> {
    
    public state = {
        dummy: "dummy",
    }
    
    public componentDidMount() {
        this.loadBookItems();
    }
    
    private callbackLoadBookItems = (sc: ServiceCall): void => {
        const bookItems: IMwdBookItems = sc.getResponseData();
        this.props.setDataFull("mwdBookItem", bookItems);
    }
    
    private loadBookItems = (): void => {
        const cs = new ServiceCall(apiServices.getBookItems);
        cs.executePost({}, this.callbackLoadBookItems);
    }
    
    private showBookItemState = (state: string): any => {
        let bgType = "danger";
        if (state === "free") {
            bgType = "success";
        } else if (state === "reserved") {
            bgType = "warning";
        } else if (state === "booked") {
            bgType = "info";
        } else if (state === "blocked") {
            bgType = "dark";
        }
        return (
            <Badge bg={bgType}>{state}</Badge>
        )
    }
    
    
    
    render() {
        
        const { appSettings, adMwdBookItems } = this.props;
        const langSetting: string = getCurrentLanguage(appSettings);
        const navHeaderRequest = getTranslationPerTopic("topMenu.item.requestForAdvice", langSetting);
        const transRequestProcessBlockRegistrationHeader = getTranslationPerTopic("requestHome.processBlock.RegistrationHeader", langSetting);
        const transRequestProcessBlockRegistrationText1 = getTranslationPerTopic("requestHome.processBlock.RegistrationText1", langSetting);
        const transRequestProcessBlockRegistrationButton = getTranslationPerTopic("requestHome.processBlock.RegistrationButton", langSetting);
        const transRequestProcessBlockAnmeldenHeader = getTranslationPerTopic("requestHome.processBlock.AnmeldungHeader", langSetting);
        const transRequestProcessBlockAnmeldenText1 = getTranslationPerTopic("requestHome.processBlock.AnmeldungText1", langSetting);
        const transRequestProcessBlockAnmeldenButton = getTranslationPerTopic("requestHome.processBlock.AnmeldungButton", langSetting);
        const transRequestProcessBlockBookingHeader = getTranslationPerTopic("requestHome.processBlock.BookingHeader", langSetting);
        const transRequestProcessBlockBookingText1 = getTranslationPerTopic("requestHome.processBlock.BookingText1", langSetting);
        const transRequestProcessBlockBookingButton = getTranslationPerTopic("requestHome.processBlock.BookingButton", langSetting);
        const transRequestProcessBlockAnticipationHeader = getTranslationPerTopic("requestHome.processBlock.AnticipationHeader", langSetting);
        const transRequestProcessBlockAnticipationText1 = getTranslationPerTopic("requestHome.processBlock.AnticipationText1", langSetting);
        const transRequestImportantBlockHeader = getTranslationPerTopic("requestHome.importantBlock.Header", langSetting);
        const transRequestImportantBlockList1 = getTranslationPerTopic("requestHome.importantBlock.List1", langSetting);
        const transRequestImportantBlockList2 = getTranslationPerTopic("requestHome.importantBlock.List2", langSetting);
        
        const dateItems: iDateItem[] = [];
        const today = new Date();
        for (let dayCounter = 0; dayCounter <= 31; dayCounter++) {
            const date = new Date(today);
            date.setDate(date.getDate() + dayCounter);
            dateItems.push({dateObject: getSpecDateObjectFromDate(date), bookItemList: []});
        }
        
        if (adMwdBookItems) {
            adMwdBookItems.forEach((bi: IMwdBookItem) => {
                const biDate: ISpecDateObjectFromDate = getSpecDateObjectFromDate((bi.bookDate));
                console.log(biDate.dateDate);
                let dItemIdx = dateItems.findIndex((di: iDateItem) => di.dateObject.dateYear === biDate.dateYear && di.dateObject.dateMonth === biDate.dateMonth && di.dateObject.dateDay === biDate.dateDay);
                console.log(dItemIdx);
                if (dItemIdx < 0) {
                    dateItems.push({dateObject: biDate, bookItemList: []});
                    dItemIdx = dateItems.length - 1;
                }
                dateItems[dItemIdx].bookItemList.push(bi);
            })
        }
        console.log(dateItems);
        
        
        return (
            <>
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"}>
                        {navHeaderRequest}
                    </div>
                </div>
                
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "20%"}}>
                            <Card>
                                <Card.Header>
                                    {transRequestProcessBlockRegistrationHeader}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {transRequestProcessBlockRegistrationText1}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant={"outline-dark"}>{transRequestProcessBlockRegistrationButton}</Button>
                                </Card.Footer>
                            </Card>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "top", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "20%"}}>
                            <Card>
                                <Card.Header>
                                    {transRequestProcessBlockAnmeldenHeader}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {transRequestProcessBlockAnmeldenText1}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant={"outline-dark"}>{transRequestProcessBlockAnmeldenButton}</Button>
                                </Card.Footer>
                            </Card>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "top", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "20%"}}>
                            <Card>
                                <Card.Header>
                                    {transRequestProcessBlockBookingHeader}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {transRequestProcessBlockBookingText1}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant={"outline-dark"}>{transRequestProcessBlockBookingButton}</Button>
                                </Card.Footer>
                            </Card>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "top", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "20%"}}>
                            <Card>
                                <Card.Header>
                                    {transRequestProcessBlockAnticipationHeader}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {transRequestProcessBlockAnticipationText1}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                
                <br/>
    
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
    
                            <b>
                                {transRequestProcessBlockRegistrationHeader}
                            </b><br />
                            {transRequestProcessBlockRegistrationText1}
                            <br />
                            <Button>{transRequestProcessBlockRegistrationButton}</Button>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transRequestProcessBlockAnmeldenHeader}
                            </b><br />
                            {transRequestProcessBlockAnmeldenText1}
                            <br />
                            <Button>{transRequestProcessBlockAnmeldenButton}</Button>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transRequestProcessBlockBookingHeader}
                            </b><br />
                            {transRequestProcessBlockBookingText1}
                            <br />
                            <Button>{transRequestProcessBlockBookingButton}</Button>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transRequestProcessBlockAnticipationHeader}
                            </b><br />
                            {transRequestProcessBlockAnticipationText1}
                        </div>
                    </div>
                </div>
                
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "100%"}}>
                            <Card>
                                <Card.Header>
                                    {transRequestImportantBlockHeader}
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        <ul>
                                            <li>
                                                {transRequestImportantBlockList1}
                                            </li>
                                            <li>
                                                {transRequestImportantBlockList2}
                                            </li>
                                        </ul>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
    
    
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <Accordion defaultActiveKey={""} >
    
                    {dateItems.map((di: iDateItem) => {
                        let numFree: number = 0;
                        let numReserved: number = 0;
                        let numBooked: number = 0;
                        let numBlocked: number = 0;
                        di.bookItemList.forEach((bi: IMwdBookItem) => {
                            if (bi.bookState === "free") {
                                numFree++;
                            } else if (bi.bookState === "reserved") {
                                numReserved++;
                            } else if (bi.bookState === "booked") {
                                numBooked++;
                            } else if (bi.bookState === "blocked") {
                                numBlocked++;
                            }
                        })
                        return (
                            <React.Fragment key={"accordDate_" + di.dateObject.dateYear + "" + di.dateObject.dateMonth + "_" + di.dateObject.dateDay}>
                                    <Accordion.Item eventKey={"acordContainer_date_" + di.dateObject.dateYear + "" + di.dateObject.dateMonth + "_" + di.dateObject.dateDay}>
                                        <Accordion.Header>
                                            <table width={"100%"}>
                                                <colgroup>
                                                    <col width={"30%"} />
                                                    <col width={"40%"} />
                                                    <col width={"30%"} />
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <b>{di.dateObject.dateDay + "." + di.dateObject.dateMonth + "." + di.dateObject.dateYear}</b>
                                                    </td>
                                                    <td>
                                                        {getWeekdayString(di.dateObject.weekDay)}
                                                    </td>
                                                    <td >
                                                        {numFree > 0 &&
                                                            <>
                                                                <Badge bg="success">{numFree}</Badge> &nbsp;
                                                            </>
                                                        }
                                                        {numReserved > 0 &&
                                                            <>
                                                                <Badge bg="warning">{numReserved}</Badge> &nbsp;
                                                            </>
                                                        }
                                                        {numBooked > 0 &&
                                                            <>
                                                                <Badge bg="info">{numBooked}</Badge> &nbsp;
                                                            </>
                                                        }
                                                        {numBlocked > 0 &&
                                                            <>
                                                                <Badge bg="dark">{numBlocked}</Badge> &nbsp;
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </Accordion.Header>
                                        <Accordion.Body>
                                            {di.bookItemList.length === 0 &&
                                                <Alert variant={"light"}>
                                                    No booking items available
                                                </Alert>
                                            }
                                            {di.bookItemList.map((bi: IMwdBookItem) => {
                                                return (
                                                    <Card key={"BookItemList.Card." + bi.bookItemId}>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                <table width={"100%"}>
                                                                    <colgroup>
                                                                        <col width={"30%"} />
                                                                        <col width={"40%"} />
                                                                        <col width={"30%"} />
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {bi.timeSlotInfo}
                                                                        </td>
                                                                        <td>
                                                                            {this.showBookItemState(bi.bookState)}
                                                                        </td>
                                                                        <td>
                                                                            {bi.coachName}
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Card.Title>
                                                            <Card.Text>
                                                                <table width={"100%"}>
                                                                    <colgroup>
                                                                        <col width={"10%"} />
                                                                        <col width={"38%"} />
                                                                        <col width={"4%"} />
                                                                        <col width={"10%"} />
                                                                        <col width={"38%"} />
                                                                    </colgroup>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Name:
                                                                        </td>
                                                                        <td>
                                                                            {bi.fullName}
                                                                        </td>
                                                                        <td />
                                                                        <td rowSpan={2}>
                                                                            Comment:
                                                                        </td>
                                                                        <td rowSpan={2}>
                                                                            {bi.customerComment}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Phone/Mail:
                                                                        </td>
                                                                        <td>
                                                                            {bi.phoneNumber} / {bi.mailAddress}
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                    </React.Fragment>
                        )
                    })}
                    </Accordion>
                    
                </div>
                
    
            </>
        )
    }
}
export const RequestHome = connect(mapStateToProps, appDataMapDispatchToProps)(RequestHomeComponent);

