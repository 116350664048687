import React from 'react';
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic} from "../../sharedLib/translationLib";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {IRootState} from "../../redux";
import appStyles from "../../css/mwlaApp.module.css";
import {Button} from "react-bootstrap";
import {getWindowSize} from "../../sharedLib/appSettingsLib.js";

//import PropTypes from 'prop-types';


interface IState {
    dummy: string;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adMwrTransTopic: state.appData.mwrTransTopic,
        appSettings: state.appData.appSettings,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & StateProps;


export default class ContactHomeComponent extends React.Component<Props, IState> {
    
    public state = {
        dummy: "dummy",
    }
    
    
    render() {
        
        const {appSettings} = this.props;
        const langSetting: string = getCurrentLanguage(appSettings);
        const navHeaderContact = getTranslationPerTopic("topMenu.item.contact", langSetting);
        const transContactAboutMeHeader = getTranslationPerTopic("contactHome.aboutMeBlock.Header", langSetting);
        const transContactAboutMeText1 = getTranslationPerTopic("contactHome.aboutMeBlock.Text1", langSetting);
        const transContactAboutMeText2 = getTranslationPerTopic("contactHome.aboutMeBlock.Text2", langSetting);
        const transContactReachMeHeader = getTranslationPerTopic("contactHome.reachMeBlock.Header", langSetting);
        const transContactReachMeText1 = getTranslationPerTopic("contactHome.reachMeBlock.Text1", langSetting);
        const transContactReachMeContactButton = getTranslationPerTopic("contactHome.reachMeBlock.ContactButton", langSetting);
        const transContactAddressHeader = getTranslationPerTopic("contactHome.addressBlock.Header", langSetting);
        
        return (
            <>
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"}>
                        {navHeaderContact}
                    </div>
                </div>
                
                <br/>
                
                {/* == About Me / Photo =========================================================================== */}
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "59%"}}>
                            <div>
                                <b>
                                    {transContactAboutMeHeader}
                                </b>
                            </div>
                            <br/>
                            <div>
                                {transContactAboutMeText1}
                            </div>
                            <br/>
                            <div>
                                {transContactAboutMeText2}
                            </div>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "2%"}}/>
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "39%", textAlign: "center"}}>
                            <img src={require("../../img/tukyInTheMorning.png")} alt="TukyMorning"/>
                        </div>
                    </div>
                </div>
                
                <br/>
    
                {/* == Reach Me / Address ========================================================================= */}
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockWhiteSmokeTdGlow} style={{width: "59%"}}>
                            <div>
                                <b>
                                    {transContactReachMeHeader}
                                </b>
                            </div>
                            <br/>
                            <div>
                                {transContactReachMeText1}
                            </div>
                            <div>
                                <Button variant={"dark"}>
                                    {transContactReachMeContactButton}
                                </Button>
                            </div>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "2%"}}/>
                        <div className={appStyles.divInfoBlockWhiteSmokeTdGlow} style={{width: "39%"}}>
                            <div>
                                <b>
                                    {transContactAddressHeader}
                                </b>
                            </div>
                            <br/>
                            <div>
                                Wassana Suphan<br/>
                                65 Moo 10<br/>
                                T. Nong Waeng A. Banphaeng<br/>
                                48140 Nakhon Phanom<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export const ContactHome = connect(mapStateToProps, appDataMapDispatchToProps)(ContactHomeComponent);

