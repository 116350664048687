import React, {useState, useEffect} from 'react';

import startStyles from './start.module.css';
import {useSelector, useDispatch} from "react-redux";
import { IRootState, APPDATA_UPDATEDATAITEM, APPDATA_ADDDATAITEM, APPDATA_SETDATAFULL, APPDATA_DELETEDATAITEM } from "../../redux";
import {ServiceCall} from "../../sharedLib/ServiceCall";
import apiServices from "../../config/apiServices.json"
import clientPaths from "../../config/clientPaths.json";


import {UserInPageHeader} from "../../app/shared/pages/user/UserInPageHeader";
import {ButtonGroup, Col, Container, Dropdown} from "react-bootstrap";
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {IAppSettings} from "../../typeDef/appSettingsData";
import {IApiFeedback} from "../../typeDef/apiFeedback";
import {
    getMultiTransTopic,
    getTranslationPerTopic,
    setTranslationData
} from "../../sharedLib/translationLib";
import {IMwrTransTopicItems} from "../../typeDef/translationData";
import {Link, useLocation} from "react-router-dom";
import {IMessageList} from "../../typeDef/messageList";

interface ILocalApiFeedback {
    state: string;
    statusCode: number;
    error: string;
}

interface IState {
    apiFeedback: ILocalApiFeedback;
    firstTimeRun: boolean;
}

const emptyMsgList: IMessageList = [];
const emptyApiFeedback: ILocalApiFeedback = {
    state: "loading",
    statusCode: 999,
    error: ''
};


export const PageHeader = () => {
    
    const dispatch = useDispatch();
    const location = useLocation();
    
    const appSettings = useSelector((state: IRootState) => state.appData.appSettings);
    const mwrTransTopic = useSelector((state: IRootState) => state.appData.mwrTransTopic)
//    const [apiFeedback, setApiFeedback] = useState({emptyApiFeedback});
    const [firstTimeRun, setFirstTimeRun] = useState(true);
    
    const locPathName: string = location.pathname;
    let urlMap = "?"; // default
    if (locPathName.startsWith(clientPaths.serviceHome) || locPathName === "/" || locPathName === "") {
        urlMap = "service";
    } else if (locPathName.startsWith(clientPaths.requestHome)) {
        urlMap = "request";
    } else if (locPathName.startsWith(clientPaths.contactHome)) {
        urlMap = "contact";
    } else if (locPathName.startsWith(clientPaths.adminHome)) {
        urlMap = "admin";
    }
    
    const langSetting: string = getCurrentLanguage(appSettings);
    
    setTranslationData(mwrTransTopic);
    const navHeaderServices = getTranslationPerTopic("topMenu.item.services", langSetting);
    const navHeaderRequestForBook =  getTranslationPerTopic("topMenu.item.requestForAdvice", langSetting);
    const navHeaderContact =  getTranslationPerTopic("topMenu.item.contact", langSetting);
    const navHeaderAdmin =  getTranslationPerTopic("topMenu.item.admin", langSetting);
    
    function getCurrentLanguageInfo() {
        const langSetting: string = getCurrentLanguage(appSettings);
        if (langSetting === "de" ) {
            return (
                <img src={require("../../img/lang_de.png")} alt={"Deutsch"} />
            )
        }
        if (langSetting ===  "en" ) {
            return (
                <img src={require("../../img/lang_en.png")} alt={"Englisch"} />
            )
        }
        if (langSetting ===  "th" ) {
            return (
                <img src={require("../../img/lang_th.png")} alt={"Thai"} />
            )
        }
        return (
            <>lang: {langSetting}</>
        )
    }
    
    function selectLanguage (newLang: string) {
        dispatch(APPDATA_UPDATEDATAITEM("appSettings", {name: "lang", value: newLang}, "name"));
    }
    
    function callbackAllTranslations(sc: ServiceCall) {
        const msgList: IMessageList = sc.getResponseInfo();
        const localApiFeedback: ILocalApiFeedback = {
            state: "loaded",
            statusCode: sc.getRequestConfigStatusCode(),
            error: ''
        }
        const translationData: IMwrTransTopicItems = sc.getResponseData();
        setTranslationData(translationData);
        dispatch(APPDATA_SETDATAFULL("mwrTransTopic", translationData));
//            setApiFeedback(localApiFeedback);
    }
    
    useEffect(() => {
        if (firstTimeRun) {
            const cs = new ServiceCall(apiServices.allTranslationPerApp);
            cs.executePost({appName: "mwla"}, callbackAllTranslations);
            setFirstTimeRun(false);
        }
    
    });
    
    return (
        <>
            <div className={startStyles.appHeader}>
                <table className={startStyles.pageHeaderTable}>
                    <colgroup>
                        <col width={"120px"} />
                        <col width={"*"} />
                        <col width={"300px"} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td rowSpan={2}>
                            <img src={require("./../../img/logoMaeWassana.png")} alt={"logo"} />
                        </td>
                        <td>
                            <div className={startStyles.appTitle}>
                                Mae Wassana Life Advice
                            </div>
                        </td>
                        <td rowSpan={2}>
                            <div className={startStyles.flexRow}>
                                <div style={{paddingRight: "20px"}}>
                                    <UserInPageHeader />
                                </div>
                                <div>
                                    <Dropdown
                                        as={ButtonGroup}
                                        key={"lang"}
                                        id={`dropdown-variants-lang`}
                                        title={"Language"}
                                    >
                                        <Dropdown.Toggle id="dropdown-custom-1" variant={"outline-secondary"}>{getCurrentLanguageInfo()}</Dropdown.Toggle>
                                        <Dropdown.Menu  >
                                            <Dropdown.Item eventKey="en" onClick={() => selectLanguage("en")}><img src={require("./../../img/lang_en.png")} alt={"English"} /> English</Dropdown.Item>
                                            <Dropdown.Item eventKey="th" onClick={() => selectLanguage("th")}><img src={require("./../../img/lang_th.png")} alt={"Thai"} /> Thai</Dropdown.Item>
                                            <Dropdown.Item eventKey="de" onClick={() => selectLanguage("de")}><img src={require("./../../img/lang_de.png")} alt={"Deutsch"} /> Deutsch</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table className={startStyles.navTable}>
                                <tbody>
                                <tr>
                                    <td className={urlMap === "service" ? startStyles.navTableTdSelected : startStyles.navTableTdUnselected}>
                                        <Link to={clientPaths.serviceHome} >
                                            {navHeaderServices}
                                        </Link>
                                    </td>
                                    <td className={urlMap === "request" ? startStyles.navTableTdSelected : startStyles.navTableTdUnselected}>
                                        <Link to={clientPaths.requestHome} >
                                            {navHeaderRequestForBook}
                                        </Link>
                                    </td>
                                    <td className={urlMap === "contact" ? startStyles.navTableTdSelected : startStyles.navTableTdUnselected}>
                                        <Link to={clientPaths.contactHome} >
                                            {navHeaderContact}
                                        </Link>
                                    </td>
                                    <td className={urlMap === "admin" ? startStyles.navTableTdSelected : startStyles.navTableTdUnselected}>
                                        <Link to={clientPaths.adminHome} >
                                            {navHeaderAdmin}
                                        </Link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
