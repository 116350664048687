import * as React from 'react';

import startStyles from './start.module.css';
import {Routes, Route} from "react-router-dom";
import {ServicesHome} from "../services/ServicesHome";
import {ContactHome} from "../contact/ContactHome";
import {RequestHome} from "../request/RequestHome";
import clientPaths from "../../config/clientPaths.json";
import {AdminHome} from "../admin/AdminHome";

export class AppRouting extends React.Component<{}, {}> {
    
    
    render() {
        return (
            <div className={startStyles.AppContentDiv}>
                <Routes>
                    <Route  path="/" element={<ServicesHome />} />
                    <Route path={clientPaths.serviceHome} element={<ServicesHome />}/>
                    <Route path={clientPaths.requestHome} element={<RequestHome />}/>
                    <Route path={clientPaths.contactHome} element={<ContactHome />}/>
                    <Route path={clientPaths.adminHome} element={<AdminHome />}/>
                    
                    <Route  path="/*" element={<ServicesHome />} />
                    
                </Routes>
            </div>        )
    }
    
}