import React from 'react';
import {connect} from "react-redux";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {IRootState} from "../../redux";
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic, setTranslationData} from "../../sharedLib/translationLib";
import appStyles from "../../css/mwlaApp.module.css";
//import PropTypes from 'prop-types';


interface IState {
    dummy: string;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adMwrTransTopic: state.appData.mwrTransTopic,
        appSettings: state.appData.appSettings,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;


class ServicesHomeComponent extends React.Component<Props, IState> {
    
    public state = {
        dummy: "dummy",
    }
    
    
    render() {
        
        const { appSettings } = this.props;
        const langSetting: string = getCurrentLanguage(appSettings);
        const navHeaderServices = getTranslationPerTopic("topMenu.item.services", langSetting);
        const transServicesInfoBlockPart1 = getTranslationPerTopic("servicesHome.infoBlock.Part1", langSetting);
        const transServicesInfoBlockList1 = getTranslationPerTopic("servicesHome.infoBlock.List1", langSetting);
        const transServicesInfoBlockList2 = getTranslationPerTopic("servicesHome.infoBlock.List2", langSetting);
        const transServicesInfoBlockList3 = getTranslationPerTopic("servicesHome.infoBlock.List3", langSetting);
        const transServicesInfoBlockList4 = getTranslationPerTopic("servicesHome.infoBlock.List4", langSetting);
        const transServicesInfoBlockList5 = getTranslationPerTopic("servicesHome.infoBlock.List5", langSetting);
        const transServicesInfoBlockList6 = getTranslationPerTopic("servicesHome.infoBlock.List6", langSetting);
        const transServicesInfoBlockList7 = getTranslationPerTopic("servicesHome.infoBlock.List7", langSetting);
        const transServicesInfoBlockList8 = getTranslationPerTopic("servicesHome.infoBlock.List8", langSetting);
        const transServicesInfoBlockPart2 = getTranslationPerTopic("servicesHome.infoBlock.Part2", langSetting);
    
        const transServicesProcessBlockHeader1 = getTranslationPerTopic("servicesHome.processBlock.Header1", langSetting);
        const transServicesProcessBlockText1 = getTranslationPerTopic("servicesHome.processBlock.Text1", langSetting);
        const transServicesProcessBlockHeader2 = getTranslationPerTopic("servicesHome.processBlock.Header2", langSetting);
        const transServicesProcessBlockText2 = getTranslationPerTopic("servicesHome.processBlock.Text2", langSetting);
        const transServicesProcessBlockHeader3 = getTranslationPerTopic("servicesHome.processBlock.Header3", langSetting);
        const transServicesProcessBlockText3 = getTranslationPerTopic("servicesHome.processBlock.Text3", langSetting);
        const transServicesProcessBlockHeader4 = getTranslationPerTopic("servicesHome.processBlock.Header4", langSetting);
        const transServicesProcessBlockText4 = getTranslationPerTopic("servicesHome.processBlock.Text4", langSetting);
    
        const transServicesCantDoBlockHeader = getTranslationPerTopic("servicesHome.cantDoBlock.Header", langSetting);
        const transServicesCantDoBlockBullet1 = getTranslationPerTopic("servicesHome.cantDoBlock.Bullet1", langSetting);
        const transServicesCantDoBlockBullet2 = getTranslationPerTopic("servicesHome.cantDoBlock.Bullet2", langSetting);
        const transServicesCantDoBlockBullet3 = getTranslationPerTopic("servicesHome.cantDoBlock.Bullet3", langSetting);
        const transServicesCantDoBlockBullet4 = getTranslationPerTopic("servicesHome.cantDoBlock.Bullet1", langSetting);
    
        const transServicesAboutMeBlockHeader = getTranslationPerTopic("servicesHome.aboutMeBlock.Header", langSetting);
        const transServicesAboutMeBlockText1 = getTranslationPerTopic("servicesHome.aboutMeBlock.Text1", langSetting);
        const transServicesAboutMeBlockText2 = getTranslationPerTopic("servicesHome.aboutMeBlock.Text2", langSetting);

        const transServicesChannelBlockHeader = getTranslationPerTopic("servicesHome.channelsBlock.Header", langSetting);
        const transServicesChannelBlockText1 = getTranslationPerTopic("servicesHome.channelsBlock.Text1", langSetting);
        const transServicesChannelBlockChannelList1 = getTranslationPerTopic("servicesHome.channelsBlock.ChannelList1", langSetting);
        const transServicesChannelBlockChannelList2 = getTranslationPerTopic("servicesHome.channelsBlock.ChannelList2", langSetting);
        
        return (
            <>
    
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"}>
                        {navHeaderServices}
                    </div>
                </div>
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "59%"}}>
                            <div>
                                {transServicesInfoBlockPart1}
                            </div>
                            <ul>
                                <li>
                                    {transServicesInfoBlockList1}
                                </li>
                                <li>
                                    {transServicesInfoBlockList2}
                                </li>
                                <li>
                                    {transServicesInfoBlockList3}
                                </li>
                                <li>
                                    {transServicesInfoBlockList4}
                                </li>
                                <li>
                                    {transServicesInfoBlockList5}
                                </li>
                                <li>
                                    {transServicesInfoBlockList6}
                                </li>
                                <li>
                                    {transServicesInfoBlockList7}
                                </li>
                                <li>
                                    {transServicesInfoBlockList8}
                                </li>
                            </ul>
                            <div>
                                {transServicesInfoBlockPart2}
                            </div>
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "2%"}} />
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "39%", textAlign: "center"}}>
                            <img src={require("../../img/tukyInTheMorning.png")} alt="photo" />
                        </div>
                    </div>
                </div>
    
                <br />
                
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transServicesProcessBlockHeader1}
                            </b><br />
                            {transServicesProcessBlockText1}
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transServicesProcessBlockHeader2}
                            </b><br />
                            {transServicesProcessBlockText2}
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transServicesProcessBlockHeader3}
                            </b><br />
                            {transServicesProcessBlockText3}
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%", verticalAlign: "middle", textAlign: "center"}}>
                            <img src={require("../../img/blueArrowRight.png")} alt={"Arrow Right"} />
                        </div>
                        <div className={appStyles.divInfoBlockLightBlueTd} style={{width: "20%"}}>
                            <b>
                                {transServicesProcessBlockHeader4}
                            </b><br />
                            {transServicesProcessBlockText4}
                        </div>
                    </div>
                </div>
    
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "100%"}}>
                            <b>
                                {transServicesCantDoBlockHeader}
                            </b>
                            <br />
                            <ul>
                                <li>
                                    {transServicesCantDoBlockBullet1}
                                </li>
                                <li>
                                    {transServicesCantDoBlockBullet2}
                                </li>
                                <li>
                                    {transServicesCantDoBlockBullet3}
                                </li>
                                <li>
                                    {transServicesCantDoBlockBullet4}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <br />
    
                <div className={appStyles.divInfoBlock}>
                    <div className={appStyles.divInfoBlockRow}>
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "59%"}}>
                            <b>
                                {transServicesAboutMeBlockHeader}
                            </b>
                            <br />
                            {transServicesAboutMeBlockText1}
                            <br/><br/>
                            {transServicesAboutMeBlockText2}
                        </div>
                        <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "2%"}} />
                        <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "39%"}}>
                            <b>
                                {transServicesChannelBlockHeader}
                            </b>
                            <br />
                            <ul>
                                <li>
                                    {transServicesChannelBlockChannelList1}
                                </li>
                                <li>
                                    {transServicesChannelBlockChannelList2}
                                </li>
                            </ul>
                            <div>
                                {transServicesChannelBlockText1}
                            </div>
                        </div>
                    </div>
                </div>
                
            
            </>
        )
    }
}
export const ServicesHome = connect(mapStateToProps, appDataMapDispatchToProps)(ServicesHomeComponent);
