import React from 'react';
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic, setTranslationData} from "../../sharedLib/translationLib";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {APPDATA_SETDATAFULL, APPDATA_UPDATEDATAITEM, IRootState} from "../../redux";
import appStyles from "../../css/mwlaApp.module.css";
import appGeneric from "../../css/appGeneric.module.css";
import {Accordion, Button, Col, Container, Row, Tab, Table, Tabs} from "react-bootstrap";
import {IMwrTransTopicItems, IMwrTransTopicItem, IMwrTransTextItem, IMwrTransTextItems} from "../../typeDef/translationData";
import {ShowTextWidget} from "../../component/input/ShowTextWidget";
import {ServiceCall} from "../../sharedLib/ServiceCall";
import apiServices from "../../config/apiServices.json";

const imgIsAvailable = require("../../img/greenOk_24px.png");
const imgIsNotAvailable = require("../../img/redNotOk_24px.png");
const editWhatTransTopic = "transTopic";
const editWhatTransText = "transText";
const emptyTransTopic: IMwrTransTopicItem = null;
const emptyTransText: ITransTextLang = null;

interface IState {
    editWhat: string;
    editDataTransTopic: IMwrTransTopicItem;
    editDataTransText: ITransTextLang;
}
interface IComponentProps {
    subTopicId: number;
    fnSelectSubTopic: (subTopicId: number, subTopicLabel: string) => void;
}
interface ITransTextLang {
    lang: string;
    hasLangEntries: boolean;
    textItem: IMwrTransTextItem;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adMwrTransTopic: state.appData.mwrTransTopic,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = IComponentProps & DispatchProps & StateProps;


export default class AdminTranslationComponent extends React.Component<Props, IState> {
    
    public state = {
        editWhat: "",
        editDataTransTopic: emptyTransTopic,
        editDataTransText: emptyTransText,
    }
    
    private setEditWhat = (editWhat: string, editData?: any): void => {
        if (editWhat === editWhatTransTopic) {
            this.setState({editWhat, editDataTransTopic: Object.assign({}, editData)});
        } else if (editWhat === editWhatTransText) {
            this.setState({editWhat, editDataTransText: Object.assign({}, editData)});
        } else if (editWhat === "") {
            this.setState({editWhat, editDataTransText: null, editDataTransTopic: null});
        }
    }
    
    private callbackSaveEditDataTransTopic = (sc: ServiceCall): void => {
        const transTopicItem: IMwrTransTopicItem = sc.getResponseData();
        if (transTopicItem && transTopicItem.transTopicId && transTopicItem.transTopicId > 0) {
            this.props.updateDataItem("mwrTransTopic", transTopicItem, "transTopicId");
            this.setState({editWhat: "", editDataTransTopic: null});
        }
    }
    
    private saveEditDataTransTopic = (): void => {
        const {editDataTransTopic} = this.state;
        if (editDataTransTopic.wsMode !== "A") {
            editDataTransTopic.wsMode = "U";
        }
        const cs = new ServiceCall(apiServices.saveTransTopic);
        cs.executePost(editDataTransTopic, this.callbackSaveEditDataTransTopic);
    }
    
    private callbackSaveEditDataTransText = (sc: ServiceCall): void => {
        const transTopicItem: IMwrTransTopicItem = sc.getResponseData();
        if (transTopicItem && transTopicItem.transTopicId && transTopicItem.transTopicId > 0) {
            this.props.updateDataItem("mwrTransTopic", transTopicItem, "transTopicId");
            this.setState({editWhat: "", editDataTransText: null});
        }
    }
    
    private saveEditDataTransText = (): void => {
        const {editDataTransText} = this.state;
        const {subTopicId} = this.props;
        console.log("Would like to save:");
        console.log(editDataTransText);
        const data = Object.assign({}, editDataTransText.textItem);
        if (editDataTransText.hasLangEntries === false) {
            data.langCode = editDataTransText.lang;
            data.transTopicId = subTopicId;
            data.wsMode = "A";
        }
        const cs = new ServiceCall(apiServices.saveTransText);
        cs.executePost(editDataTransText, this.callbackSaveEditDataTransText);
    }
    
    private onChangeHandlerTransformed = (name: string, value: any): void => {
        if (["transApp", "transSubject", "transTechCode"].includes(name)) {
            const editDataTransTopic: any = Object.assign({}, this.state.editDataTransTopic);
            editDataTransTopic[name] = value;
            this.setState({editDataTransTopic});
        } else if (["transText"].includes(name)) {
            const editDataTransText: any = Object.assign({}, this.state.editDataTransText);
            editDataTransText.textItem[name] = value;
            this.setState({editDataTransText});
        }
    }
    
    render() {
    
        const { adMwrTransTopic, subTopicId, fnSelectSubTopic } = this.props;
        const { editWhat, editDataTransTopic, editDataTransText } = this.state;
        
        const allTranslations: IMwrTransTopicItems = adMwrTransTopic ? adMwrTransTopic : [];
        
        const allLang: string[] = [];
        
        allTranslations.forEach((topic: IMwrTransTopicItem ) => {
            topic.transTextList.forEach((text: IMwrTransTextItem) => {
                const allLangIdx = allLang.findIndex((lang: string) => lang === text.langCode);
                if (allLangIdx < 0) {
                    allLang.push(text.langCode);
                }
            })
        })
        
        let selectedTransTopic: IMwrTransTopicItem = null;
        const textPerLangList: ITransTextLang[] = [];
        if (subTopicId > 0) {
            const foundTransTopic = allTranslations.find((topic: IMwrTransTopicItem) => topic.transTopicId === subTopicId);
            if (foundTransTopic) {
                selectedTransTopic = Object.assign({}, foundTransTopic);
            }
            if (selectedTransTopic) {
                allLang.forEach((lang: string) => {
                    let numLang: number = 0;
                    selectedTransTopic.transTextList.forEach((text: IMwrTransTextItem) => {
                        if (text.langCode === lang) {
                            numLang++;
                            textPerLangList.push({lang: lang, hasLangEntries: true, textItem: Object.assign({}, text)});
                        }
                    })
                    if (numLang === 0) {
                        textPerLangList.push({lang: lang, hasLangEntries: false, textItem: null});
                    }
                })
            }
        }
    
        const showDataTransTopic: IMwrTransTopicItem = editWhat === editWhatTransTopic ? editDataTransTopic : selectedTransTopic
    
        console.log("editDataTransTopic:")
        console.log(editDataTransTopic);
        console.log("showDataTransTopic:")
        console.log(showDataTransTopic);
        console.log("editDataTransText:")
        console.log(editDataTransText);
        
        const editTransTopic: boolean = editWhat === editWhatTransTopic;
        const editTransText: boolean = editWhat === editWhatTransText;
        
        return (
            <>
                <br/>
    
                {(!selectedTransTopic) &&
                    <div className={appGeneric.divTable100}>
                        <div className={appGeneric.divTableRow}>
                            <div className={appGeneric.divTableHeaderCell} style={{width: "10%"}}>#</div>
                            <div className={appGeneric.divTableHeaderCell} style={{width: "10%"}}>App</div>
                            <div className={appGeneric.divTableHeaderCell} style={{width: "40%"}}>Tech-Code</div>
                            <div className={appGeneric.divTableHeaderCell} style={{width: "40%"}}>Subject</div>
                        </div>
                        {allTranslations.map((topic: IMwrTransTopicItem) => {
                            return (
                                <div className={appGeneric.divTableRow} key={"topicRow_" + topic.transTopicId} onClick={() => fnSelectSubTopic(topic.transTopicId, topic.transSubject)} >
                                    <div className={appGeneric.divTableCell}>{topic.transTopicId}</div>
                                    <div className={appGeneric.divTableCell}>{topic.transApp}</div>
                                    <div className={appGeneric.divTableCell}>{topic.transTechCode}</div>
                                    <div className={appGeneric.divTableCell}>{topic.transSubject}</div>
                                </div>
                            )
                        })}

                    </div>
                }

                {selectedTransTopic &&
                    <>
                        <div className={appGeneric.divSectionHeader}>
                            <div style={{float: "left"}}>
                                Translation Topic
                            </div>
                            <div style={{float: "right"}}>
                                {editWhat === "" &&
                                    <img src={require("../../img/edit_24px.png")} alt={"Edit"} onClick={() => this.setEditWhat(editWhatTransTopic, selectedTransTopic)} />
                                }
                                {editWhat === editWhatTransTopic &&
                                    <>
                                        <img src={require("../../img/save_ok_24px.png")} alt={"Save"} style={{paddingRight: "20px"}} onClick={this.saveEditDataTransTopic} />
                                        <img src={require("../../img/cancel_24px.png")} alt={"Cancek"} onClick={() => this.setEditWhat("")} />
                                    </>
                                }
                            </div>
                            
                        </div>
                        <div className={appGeneric.divFormTable100}>
                            <div className={appGeneric.divTableFormRow}>
                                <div className={appGeneric.divTableFormLabelCell} style={{width: "15%"}}>App:</div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "30%"}}>
                                    <ShowTextWidget name={"transApp"} type={"text"} isEditable={editTransTopic} value={showDataTransTopic.transApp} fnChangeHandler={this.onChangeHandlerTransformed} />
                                </div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "55%"}} />
                            </div>
                            <div className={appGeneric.divTableFormRow}>
                                <div className={appGeneric.divTableFormLabelCell}>Subject:</div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "80%"}}>
                                    <ShowTextWidget name={"transSubject"} type={"text"} isEditable={editTransTopic} value={showDataTransTopic.transSubject} fnChangeHandler={this.onChangeHandlerTransformed} />
                                </div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "5%"}}/>
                            </div>
                            <div className={appGeneric.divTableFormRow}>
                                <div className={appGeneric.divTableFormLabelCell}>Tech-Code:</div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "80%"}}>
                                    <ShowTextWidget name={"transTechCode"} type={"text"} isEditable={editTransTopic} value={showDataTransTopic.transTechCode} fnChangeHandler={this.onChangeHandlerTransformed} />
                                </div>
                                <div className={appGeneric.divTableFormTextCell} style={{width: "5%"}}/>
                            </div>
                        </div>
                        <div className={appGeneric.divSectionHeader}>
                            <br />
                            Translation Text
                        </div>
                        <div className={appGeneric.divTable100} style={{paddingLeft: "30px"}}>
                            <div className={appGeneric.divTableRow}>
                                <div className={appGeneric.divTableHeaderCell} style={{width: "15%"}}>Language</div>
                                <div className={appGeneric.divTableHeaderCell} style={{width: "15%"}}>Available</div>
                                <div className={appGeneric.divTableHeaderCell} style={{width: "65%"}}>Text</div>
                                <div className={appGeneric.divTableHeaderCell} style={{width: "5%"}} />
                            </div>
                            {textPerLangList.map((tpll: any) => {
                                const isEditTransTextRow = editTransText && editDataTransText.textItem.transTextId === tpll.textItem.transTextId;
                                const showDataTransText: ITransTextLang = editWhat === editWhatTransText && editDataTransText.textItem.transTextId === tpll.textItem.transTextId ? Object.assign({}, editDataTransText) : Object.assign({}, tpll);
                                return (
                                    <div className={appGeneric.divTableRow} key={"trLang_" + tpll.lang + "_" + tpll.textItem.transTextId}>
                                        <div className={appGeneric.divTableCell}>
                                            {tpll.lang}
                                        </div>
                                        <div className={appGeneric.divTableCell}>
                                            <img src={tpll.hasLangEntries === true ? imgIsAvailable : imgIsNotAvailable} alt={"Availability"} />
                                        </div>
                                        <div className={appGeneric.divTableCell}>
                                            <ShowTextWidget name={"transText"} type={"textarea"} isEditable={isEditTransTextRow} value={showDataTransText.textItem.transText} fnChangeHandler={this.onChangeHandlerTransformed} />
                                        </div>
                                        <div className={appGeneric.divTableCell}>
                                            {editWhat === "" &&
                                                <img src={require("../../img/edit_24px.png")} alt={"Edit"} onClick={() => this.setEditWhat(editWhatTransText, tpll)} />
                                            }
                                            {editWhat === editWhatTransText && tpll.textItem.transTextId === editDataTransText.textItem.transTextId &&
                                                <>
                                                    <img src={require("../../img/save_ok_24px.png")} alt={"Save"} style={{paddingRight: "20px"}} onClick={this.saveEditDataTransText} />
                                                    <img src={require("../../img/cancel_24px.png")} alt={"Cancek"} onClick={() => this.setEditWhat("")} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
                
            </>
        )
    }
}
export const AdminTranslation = connect(mapStateToProps, appDataMapDispatchToProps)(AdminTranslationComponent);

