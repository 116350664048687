import React from 'react';
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic, setTranslationData} from "../../sharedLib/translationLib";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {APPDATA_SETDATAFULL, APPDATA_UPDATEDATAITEM, IRootState} from "../../redux";
import appStyles from "../../css/mwlaApp.module.css";
import appGeneric from "../../css/appGeneric.module.css";
import {Accordion, Button, Col, Container, Row, Tab, Table, Tabs} from "react-bootstrap";
import {IMwrTransTopicItems, IMwrTransTopicItem, IMwrTransTextItem, IMwrTransTextItems} from "../../typeDef/translationData";
import {ShowTextWidget} from "../../component/input/ShowTextWidget";
import {ServiceCall} from "../../sharedLib/ServiceCall";
import apiServices from "../../config/apiServices.json";
import {IMwdBookItems} from "../../typeDef/bookItem";
import {IMwdCustomerItems} from "../../typeDef/customer";

const imgIsAvailable = require("../../img/greenOk_24px.png");
const imgIsNotAvailable = require("../../img/redNotOk_24px.png");
const editWhatTransTopic = "transTopic";
const editWhatTransText = "transText";
const emptyTransTopic: IMwrTransTopicItem = null;
const emptyTransText: ITransTextLang = null;

interface IState {
    dummy: string;
}
interface IComponentProps {
}
interface ITransTextLang {
    lang: string;
    hasLangEntries: boolean;
    textItem: IMwrTransTextItem;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adMwdCustomers: state.appData.mwrTransTopic,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = IComponentProps & DispatchProps & StateProps;

export default class AdminCustomerComponent extends React.Component<Props, IState> {
    
    public state = {
        dummy: "",
    }
    
    public componentDidMount() {
        this.loadCustomers();
    }
    
    private loadCustomers = (): void => {
        const cs = new ServiceCall(apiServices.getCustomers);
        cs.executePost({}, this.callbackLoadCustomers);
    }
    
    private callbackLoadCustomers = (sc: ServiceCall): void => {
        const customers: IMwdCustomerItems = sc.getResponseData();
        this.props.setDataFull("mwdCustomer", customers);
    }
    
    render() {
    
        const { adMwdCustomers } = this.props;
        const { dummy,  } = this.state;
        
    
        return (
            <>
                <h3>Customer Listing</h3>
                  <br/>
    
            </>
        )
    }
}
export const AdminCustomer = connect(mapStateToProps, appDataMapDispatchToProps)(AdminCustomerComponent);

