import React from 'react';
import {Badge, Nav} from "react-bootstrap";
import {LoginForm} from "./LoginForm";
import {IRootState} from "../../../../redux";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
//import PropTypes from 'prop-types';

interface IState {
    showUserLoginForm:boolean;
}

const mapStateToProps = (state: IRootState) => {
    return {
        currentUser: state.appData.currentUser
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

export class UserInPageHeaderComponent extends React.Component<Props, IState> {
    
    public state = {
        showUserLoginForm: false,
    }
    
    private openUserLogin = (): void => {
        this.setState({showUserLoginForm: true});
    }
    
    private closeUserLogin = (): void => {
        this.setState({showUserLoginForm: false});
    }
    
    
    render() {
        
        const { showUserLoginForm } = this.state;
        const { currentUser } = this.props;
        
        const userIsLoggedIn: boolean = (currentUser && currentUser.length > 0) ? true : false;
        const userNameSplit: string[] = userIsLoggedIn ? currentUser[0].showName.split(" ") : [];
        const userCaps = userNameSplit.map((name: string) => {
            return name.substring(0,1).toUpperCase();
        })
        
        return (
            <>
                    {userIsLoggedIn === true &&
                        <Nav.Link href={"#"} onClick={this.openUserLogin}>
                            <h3>
                                <Badge bg="secondary" pill={true}>
                                    {userCaps}
                                </Badge>
                            </h3>
                        </Nav.Link>
                    }
                    {userIsLoggedIn === false &&
                        <Nav.Link href={"#"} onClick={this.openUserLogin}><img src={require("../../../../img/login_32px.png")} /></Nav.Link>
                    }
                    {showUserLoginForm === true &&
                        <LoginForm fnOnClose={this.closeUserLogin} />
                    }
            </>
        )
    }
}

export const UserInPageHeader = connect(mapStateToProps, appDataMapDispatchToProps)(UserInPageHeaderComponent);