import React from 'react';
import {getCurrentLanguage} from "../../sharedLib/appSettingsLib.js";
import {getTranslationPerTopic} from "../../sharedLib/translationLib";
import {appDataMapDispatchToProps} from "../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {IRootState} from "../../redux";
import appStyles from "../../css/mwlaApp.module.css";
import {Button, Tab, Tabs} from "react-bootstrap";
import {AdminTranslation} from "./AdminTranslation";
import {AdminCustomer} from "./AdminCustomer";
//import PropTypes from 'prop-types';


interface IState {
    topic: string;
    subTopicId: number;
    subTopicLabel: string;
}

const topicTrans = "Translations";
const topicCustomers = "Customers";


const mapStateToProps = (state: IRootState) => {
    return {
        adMwrTransTopic: state.appData.mwrTransTopic,
        appSettings: state.appData.appSettings,
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;


export default class AdminHomeComponent extends React.Component<Props, IState> {
    
    public state = {
        topic: "",
        subTopicId: 0,
        subTopicLabel: "",
    }
    
    private selectTopic = (topic: string): void => {
        this.setState({topic, subTopicId: 0, subTopicLabel: ""});
    }
    
    private selectSubTopic = (subTopicId: number, subTopicLabel: string): void => {
        this.setState({subTopicId, subTopicLabel});
    }
    
    
    render() {
    
        const { appSettings } = this.props;
        const { topic, subTopicId, subTopicLabel } = this.state;
        const langSetting: string = getCurrentLanguage(appSettings);
        const navHeaderAdmin = getTranslationPerTopic("topMenu.item.admin", langSetting);
        
        return (
            <>
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectTopic("")}>
                        {navHeaderAdmin}
                    </div>
                    {topic && topic !== "" &&
                        <>
                            <div className={"titleNavLineSeperator"} />
                            <div className={"titleNavLineItem"} onClick={() => this.selectTopic(topicTrans)}>
                                {topic}
                            </div>
                            {subTopicId > 0 &&
                                <>
                                    <div className={"titleNavLineSeperator"} />
                                    <div className={"titleNavLineItem"}>
                                        {subTopicLabel}
                                    </div>
                                </>
                            }
                        </>
                    }
                    
                </div>
                <br/>
    
                {topic === "" &&
                    <>
                        <div className={appStyles.divInfoBlock}>
                            <div className={appStyles.divInfoBlockRow}>
                                <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "20%", textAlign: "center"}} onClick={() => this.selectTopic(topicTrans)}>
                                    <div>
                                        <img src={require("../../img/translations_64px.png")} alt={"Translations"} />
                                    </div>
                                    <div>
                                        <b>Translations</b>
                                    </div>
                                </div>
                                <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%"}} />
                                <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "20%", textAlign: "center"}} onClick={() => this.selectTopic(topicCustomers)}>
                                    <div>
                                        <img src={require("../../img/customer_64px.png")} alt={"Customers"} />
                                    </div>
                                    <div>
                                        <b>Customers</b>
                                    </div>
                                </div>
                                <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%"}} />
                                <div className={appStyles.divInfoBlockWhiteSmokeTd} style={{width: "20%", textAlign: "center"}}>
                                    <div>
                                        <img src={require("../../img/question_64px.png")} alt={"Question"} />
                                    </div>
                                    <div>
                                        <b>Others</b>
                                    </div>
                                </div>
                                <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "5%"}} />
                                <div className={appStyles.divInfoBlockSpaceBlockWhite} style={{width: "50%"}} />

                            </div>
                        </div>
                    </>
                }
    
                {topic === topicTrans &&
                    <AdminTranslation subTopicId={subTopicId} fnSelectSubTopic={this.selectSubTopic} />
                }
    
                {topic === topicCustomers &&
                    <AdminCustomer />
                }
                
                
                
    
                
            
            </>
        )
    }
}
export const AdminHome = connect(mapStateToProps, appDataMapDispatchToProps)(AdminHomeComponent);

